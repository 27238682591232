export const KEY_INDICATORS = ['FPI', 'T100', 'T500', 'T300', 'T600', 'T200', 'T400', 'P'];

export const DISPLAY_INDICES = ['T100', 'T500', 'T300', 'T600', 'T200', 'T400', 'P'];

export const TREND_INDICATORS = ['T100', 'T500', 'T300', 'T600', 'T200', 'T400'];

export const SCALE_MIN = 0;

export const SCALE_MAX = 100;

export const SCALE_INCREMENT = 10;

export const SCORE_PRECISION = 2;
