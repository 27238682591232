export default class DownloadModalBehaviour {
  static id = 'download-modal';

  constructor(node) {
    const triggerNode = node.querySelector('[data-ref="trigger"]');
    this.triggerNode = triggerNode;
    this.templateNode = triggerNode.nextElementSibling;

    triggerNode.addEventListener('click', () => {
      this.show();
    });
  }

  show = () => {
    const fragment = this.templateNode.content.cloneNode(true);
    fragment.querySelector('[data-ref="close"]').addEventListener('click', () => {
      this.hide();
    });
    fragment.querySelector('[data-ref="shade"]').addEventListener('click', () => {
      this.hide();
    });
    fragment.querySelector('script').addEventListener('load', () => {
      this.modalNode.removeAttribute('aria-busy');
    });
    this.modalNode = fragment.querySelector('div');
    document.body.appendChild(fragment);
  };

  hide = () => {
    this.modalNode.parentNode.removeChild(this.modalNode);
    this.triggerNode.focus();
  };
}
