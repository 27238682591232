import React from 'react';
import { SizeMe } from 'react-sizeme';
import { connect } from 'redux-zero/react';

import ChartTooltip from 'components/react/ChartTooltip';
import RadarChartSvg from 'components/react/RadarChartSvg';
import Select from 'components/react/Select';

import { addRanks } from 'utils/data.js';
import { actions } from '../../store.js';

import { SCORE_PRECISION } from '../../constants';

import countries from 'data/countries.json';
import groups from 'data/groups.json';
import strings from 'data/strings.json';

import './styles.scss';

const GROUP_OPTIONS = [['regions', 'Regions'], ['incomeLevels', 'Income Levels']].map((g) => {
  const [key, label] = g;
  return {
    label,
    options: Object.keys(groups[key]).map((value) => ({
      value,
      label: groups[key][value].name,
    })),
  };
});
const COMPARISON_OPTIONS = [
  ...GROUP_OPTIONS,
  {
    label: 'Countries',
    options: Object.keys(countries).map((iso) => ({
      value: iso,
      label: countries[iso].name,
    })),
  },
];

class RadarChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeSeriesId: null,
      tooltip: null,
    };
  }

  componentDidMount() {
    if (!this.props.data && this.props.fetchData) {
      this.props.fetchData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.activeSeriesId && prevState.activeSeriesId !== this.state.activeSeriesId) {
      const seriesId = this.state.activeSeriesId;
      const rankedData = addRanks(this.props.data, [seriesId]);
      const datum = rankedData.find(
        (d) => d.id === seriesId && d.country === this.props.activeCountryId
      );
      this.setState({
        tooltip: {
          title: strings[`indicator-title-${seriesId}`],
          primaryText: `Score ${datum.score.toFixed(SCORE_PRECISION)}`,
          secondaryText: `Rank ${datum.rank}`,
        },
      });
    }
  }

  onMouseOver = (seriesId) => (event) => {
    this.setState({
      activeSeriesId: seriesId,
    });
  };

  onMouseOut = (event) => {
    // XXX: Prevent Safari from generating mouse events if the user taps quickly
    if (event.type === 'touchend' || event.type === 'touchcancel') {
      event.preventDefault();
    }

    this.setState({
      activeSeriesId: null,
      tooltip: null,
    });
  };

  handleSelectChange = (event) => {
    const id = event.target.value;
    if (this.props.setActiveComparison) {
      this.props.setActiveComparison(id);
    }
  };

  render() {
    const {
      data,
      className,
      activeCountryId,
      activeComparisonId,
      showComparators,
      showDistributions,
    } = this.props;
    const { tooltip, activeSeriesId } = this.state;

    return (
      <div className={`radar-chart ${className || ''}`}>
        <div className="radar-chart__header">
          <label htmlFor="comparison">{strings['table-label-comparison']}</label>
          <Select
            id="comparison"
            optGroups={COMPARISON_OPTIONS}
            selectedOption={activeComparisonId}
            defaultOption="Select comparison…"
            disabledOption={activeCountryId}
            changeHandler={this.handleSelectChange}
          />
        </div>
        <div className={'radar-chart__grid'}>
          <div className={'radar-chart__inner'}>
            <SizeMe monitorHeight>
              {({ size }) => (
                <div>
                  <RadarChartSvg
                    className={'radar-chart__svg'}
                    size={size}
                    data={data}
                    activeCountryId={activeCountryId}
                    activeComparisonId={activeComparisonId}
                    activeSeriesId={activeSeriesId}
                    showComparators={showComparators}
                    showDistributions={showDistributions}
                    onMouseOver={this.onMouseOver}
                    onMouseOut={this.onMouseOut}
                  />
                  {tooltip && activeCountryId && (
                    <ChartTooltip
                      className={'radar-chart__tooltip'}
                      title={tooltip.title}
                      primaryText={tooltip.primaryText || ''}
                      secondaryText={tooltip.secondaryText || ''}
                    />
                  )}
                </div>
              )}
            </SizeMe>
          </div>
        </div>
      </div>
    );
  }
}

export default RadarChart;

function mapStateToProps({ data, activeComparisonId }) {
  return { data, activeComparisonId };
}

const ConnectedRadarChart = connect(
  mapStateToProps,
  actions
)(RadarChart);

export { ConnectedRadarChart };
