import React from 'react';
import { createPortal } from 'react-dom';

import normalisePointerEvent from 'utils/normalisePointerEvent';
import { getPointerType, eventMatchesPointerType } from '../../../utils/pointer.js';
import { supportsPassiveEvents } from '../../../utils/supports.js';

import './styles.scss';

const PORTAL_ID = 'portal-tooltips';

class ChartTooltip extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      x: null,
      y: null,
      width: 0,
    };

    this.rootRef = React.createRef();

    let targetNode = document.getElementById(PORTAL_ID);
    if (!targetNode) {
      targetNode = document.createElement('div');
      targetNode.id = PORTAL_ID;
      document.body.appendChild(targetNode);
    }
    this.targetNode = targetNode;
  }

  componentDidMount() {
    document.body.addEventListener('mousemove', this.handlePointerMove);
    document.addEventListener(
      'touchstart',
      this.handlePointerMove,
      supportsPassiveEvents ? { passive: true } : false
    );
  }

  componentWillUnmount() {
    document.body.removeEventListener('mousemove', this.handlePointerMove);
    document.body.removeEventListener('touchstart', this.handlePointerMove);
  }

  componentDidUpdate() {
    if (!this.state.width) {
      this.setState({
        width: this.rootRef.current.offsetWidth,
      });
    }
  }

  handlePointerMove = (event) => {
    if (!eventMatchesPointerType(event)) {
      return;
    }

    const { x, y } = normalisePointerEvent(event);
    this.setState({ x, y });
  };

  render() {
    const { className, title, primaryText, secondaryText } = this.props;
    const { width, x, y } = this.state;
    const flip = document.documentElement.clientWidth - x < width;
    return createPortal(
      <div
        className={`
          chart-tooltip
          ${flip ? 'is-flipped' : ''}
          ${getPointerType() === 'touch' ? 'is-touch' : ''}
          ${className || ''}
        `}
        style={{
          top: `${y}px`,
          left: `${x}px`,
        }}
      >
        <div className="chart-tooltip__main" ref={this.rootRef}>
          {title && <h4 className={'chart-tooltip__title'}>{title}</h4>}
          {primaryText && <p className={'chart-tooltip__primary-text'}>{primaryText}</p>}
          {secondaryText && <p className={'chart-tooltip__secondary-text'}>{secondaryText}</p>}
        </div>
      </div>,
      document.getElementById('portal-tooltips')
    );
  }
}

export default ChartTooltip;
