export default class BackgroundBehaviour {
  static id = 'background';

  constructor(node) {
    this.node = node;
    this.subjectNode = document.querySelector(node.dataset.subject);

    window.addEventListener('resize', this.layout);

    this.layout();
  }

  layout = () => {
    const rect = this.subjectNode.getBoundingClientRect();
    const y = window.pageYOffset + rect.bottom;
    this.node.style.top = `${y}px`;
  };
}
