import React from 'react';

import convertRange from 'utils/convertRange';
import {
  axisLabelGenerator,
  axisLineGenerator,
  maskSliceGenerator,
  octagonGenerator,
  scaleLabelGenerator,
  seriesPolygonGenerator,
} from './shapeGenerators';

import strings from 'data/strings.json';

import { DISPLAY_INDICES, SCALE_MIN, SCALE_MAX, SCALE_INCREMENT } from '../../../constants';

import './styles.scss';

const SCALE_POINTS = [];
for (let i = SCALE_MIN; i <= SCALE_MAX; i += SCALE_INCREMENT) {
  SCALE_POINTS.push(i);
}

const RADIUS_NUDGE_FACTOR = 1.075;
const RADIUS_NUDGE_BREAKPOINT = 664;

const INNER_DIAMETER_MOBILE = 60;
const INNER_DIAMETER_DESKTOP = 95;
const INNER_DIAMETER_BREAKPOINT = 350;

class RadarChartSvg extends React.PureComponent {
  render() {
    const {
      className,
      size,
      data,
      activeCountryId,
      activeComparisonId,
      activeSeriesId,
      showComparators,
      showDistributions,
      onMouseOver,
      onMouseOut,
      urls,
    } = this.props;

    const countryData =
      data &&
      DISPLAY_INDICES.map((id) => {
        const scores = data
          .filter((d) => d.id === id)
          .sort((a, b) => a.score - b.score)
          .reverse();
        return {
          id,
          title: strings[`indicator-short-title-${id}`] || '',
          score: scores.find((d) => d.country === activeCountryId).score,
          highScore: scores[0].score,
        };
      }).reverse();

    const { width, height } = size;

    const minRadius =
      (Math.min(width, height) < INNER_DIAMETER_BREAKPOINT
        ? INNER_DIAMETER_MOBILE
        : INNER_DIAMETER_DESKTOP) / 2;
    // TODO: improve this to avoid magic number (calculate radius differential?) and clientWidth test
    const maxRadius =
      (Math.min(width, height) / 2) *
      (document.documentElement.clientWidth < RADIUS_NUDGE_BREAKPOINT ? RADIUS_NUDGE_FACTOR : 1);
    const originX = width / 2;
    const originY = height / 2;
    const toScale = (i) => convertRange(i, SCALE_MIN, SCALE_MAX, minRadius, maxRadius);

    return (
      <svg
        className={`radar-chart-svg ${className || ''}`}
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        height={height}
        focusable="false"
        // Prevent context menu being shown when tapping and holding segments on Android Chrome
        onContextMenu={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <mask id={'cutout'}>
          <rect x={0} y={0} width={width} height={height} fill={'white'} />
          <path
            d={maskSliceGenerator(minRadius, maxRadius)}
            transform={`translate(${originX} ${originY})`}
            fill={'black'}
          />
        </mask>
        <g mask={'url(#cutout)'}>
          <g transform={`translate(${originX} ${originY})`}>
            {SCALE_POINTS.map((p, i) => octagonGenerator(i, toScale(p)))}
            {axisLineGenerator(minRadius, maxRadius)}
          </g>
        </g>
        {countryData && (
          <>
            <g transform={`translate(${originX} ${originY})`}>
              {scaleLabelGenerator(SCALE_POINTS, minRadius, maxRadius)}
            </g>
            <g transform={`translate(${originX} ${originY})`} className="radar-chart-svg__shapes">
              {countryData.map((d, i) => {
                const position = countryData.length - i;
                const first = i === 0;
                const last = i === countryData.length - 1;
                const id = d.id;
                const score = toScale(d.score);
                const highScore = toScale(d.highScore);
                const url = urls && urls[d.id];

                let seriesData = false;
                if (showDistributions) {
                  seriesData = data
                    .filter((_d) => _d.id === d.id && !d.aggregate)
                    .sort((a, b) => a.score - b.score)
                    .reverse()
                    .slice(1) // Strip top value as it clashes with highscore line
                    .map((_d) => ({ ..._d, score: toScale(_d.score) }));
                }

                let comparator = false;
                if (showComparators && activeComparisonId && seriesData) {
                  const comparatorRaw = data.find(
                    (datum) => datum.id === d.id && datum.country === activeComparisonId
                  );
                  comparator = toScale(comparatorRaw.score);
                }

                // TODO: Refactor
                return seriesPolygonGenerator(
                  position,
                  first,
                  last,
                  id,
                  score,
                  highScore,
                  seriesData,
                  comparator,
                  activeSeriesId,
                  minRadius,
                  maxRadius,
                  onMouseOver,
                  onMouseOut,
                  url
                );
              })}
            </g>
            <g transform={`translate(${originX} ${originY})`}>
              {countryData.map((d, i) => {
                const position = countryData.length - i;
                return axisLabelGenerator(position, maxRadius, d.title);
              })}
            </g>
          </>
        )}
      </svg>
    );
  }
}

export default RadarChartSvg;
