import React from 'react';

import strings from 'data/strings.json';

import './styles.scss';

const WorldMapScale = function({ className, scale }) {
  const range = scale.range();
  range.reverse();
  return (
    <div className={`scale ${className || ''}`}>
      <ul className={'scale__list'}>
        {range.map((d, i) => {
          return (
            <li className={'scale__item'} key={d}>
              <span className={'scale__color'} style={{ background: d }}></span>
              <span className={'scale__label'}>{strings[`indicator-quartile-${i + 1}`]}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default React.memo(WorldMapScale);
