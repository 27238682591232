import React from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import Icon from '../Icon';
import IconButton from '../IconButton';

import './styles.scss';

const PORTAL_ID = 'portal-info-popover';

class InfoPopover extends React.PureComponent {
  constructor(props) {
    super(props);

    let targetNode = document.getElementById(PORTAL_ID);
    if (!targetNode) {
      targetNode = document.createElement('div');
      targetNode.id = PORTAL_ID;
      document.body.appendChild(targetNode);
    }
    this.targetNode = targetNode;

    this.mainNode = React.createRef();
  }

  componentDidMount() {}

  componentWillUnmount() {
    try {
      document.removeEventListener('click', this.handleClickOutside);
    } catch (err) {}
  }

  componentDidUpdate(prevProps) {
    if (this.props.show === prevProps.show) {
      return;
    }

    if (this.props.show) {
      window.requestAnimationFrame(() => {
        document.addEventListener('click', this.handleClickOutside);
      });
    } else {
      try {
        document.removeEventListener('click', this.handleClickOutside);
      } catch (err) {}
    }
  }

  handleClickOutside = (event) => {
    if (!this.mainNode.current || !this.props.show) {
      return;
    }

    if (!this.mainNode.current.contains(event.target)) {
      this.props.onClose();
    }
  };

  render() {
    const { onClose, data, show } = this.props;

    return createPortal(
      <div className={`${PORTAL_ID}__grid`}>
        <div className={`${PORTAL_ID}__inner`}>
          <CSSTransition in={show && data} timeout={400} classNames="slide" unmountOnExit>
            {data && (
              <div className={'info-popover'} ref={this.mainNode}>
                {data.title && <h2 className={'info-popover__title'}>{data.title}</h2>}
                {onClose && (
                  <button className={'info-popover__close'} onClick={onClose} aria-label={'Close'}>
                    <Icon id={'close'} />
                  </button>
                )}
                <table className={'info-popover__table'}>
                  <thead>
                    <tr>
                      {data.columns.map((c, i) => (
                        <th key={i}>{c}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.rows.map((row, i) => (
                      <tr key={i}>
                        {row.map((cell, j) => (
                          <td key={j}>{cell}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </CSSTransition>
        </div>
      </div>,
      document.getElementById('portal-info-popover')
    );
  }
}

export default InfoPopover;
