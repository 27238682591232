import React from 'react';
import { connect } from 'redux-zero/react';

import Icon from 'components/react/Icon';
import InfoPopover from 'components/react/InfoPopover';
import {
  ExpandButton,
  Header,
  HeaderCell,
  HeaderRow,
  Row,
  RowGroup,
  Table,
} from 'components/react/Table';

import dataSorter from 'utils/dataSorter';
import rankScores from 'utils/rankScores';

import { actions } from '../../store.js';
import { DISPLAY_INDICES, TREND_INDICATORS, SCALE_MAX, SCORE_PRECISION } from '../../constants';
import { calculateTotalScores } from '../../utils/data.js';

import indicatorSources from 'data/indicator-sources.json';
import palettes from 'data/palettes.json';
import strings from 'data/strings.json';
import structure from 'data/structure.json';

const sorter = dataSorter(SCORE_PRECISION);

const INDEX_DEFINITIONS = ['FPI', ...DISPLAY_INDICES].map((index) =>
  structure.find((i) => i.id === index)
);
INDEX_DEFINITIONS.forEach((index) => {
  if (DISPLAY_INDICES.includes(index.id)) {
    structure.filter((i) => i.parent === index.id).forEach((i) => INDEX_DEFINITIONS.push(i));
  }
});

class ResultsTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      expandedIndicators: new Set([]),
      enabledIndicators: new Set([...TREND_INDICATORS]),
      activePopoverContent: null,
      showPopover: false,
    };
  }

  componentDidMount() {
    if (!this.props.data && this.props.fetchData) {
      this.props.fetchData();
    }
  }

  deriveStateFromProps(props, enabledIndicators) {
    if (!props.data) {
      return {};
    }

    const { activeCountryId, activeComparisonId, data } = props;

    const calculatedData = calculateTotalScores(data, enabledIndicators);

    let countryCount;
    const countryData = INDEX_DEFINITIONS.map((index) => {
      const indexData = calculatedData
        .filter((d) => !d.aggregate)
        .filter((d) => d.id === index.id)
        .sort(sorter)
        .reverse();

      rankScores(indexData);

      if (!countryCount) countryCount = indexData.length;

      const { rank, score } = indexData.find((d) => d.country === activeCountryId);
      const comparator = activeComparisonId
        ? calculatedData.find((d) => d.id === index.id && d.country === activeComparisonId).score
        : null;
      const highScore = indexData[0].score;

      const color =
        index.id in palettes
          ? palettes[index.id][0]
          : index.parent in palettes
          ? palettes[index.parent][0]
          : false;

      const hasFootnote = strings[`indicator-footnote-${index.id}`];
      const name =
        (strings[`indicator-title-${index.id}`] || index.title) + (hasFootnote ? '*' : '');

      return {
        id: index.id,
        level: index.level,
        parent: index.parent,
        rank,
        name: name,
        score,
        comparator,
        highScore,
        color,
      };
    });

    return {
      countryCount,
      countryData,
    };
  }

  headerCellClickHandler = () => {
    const expandedIndicators = new Set(
      this.state.expandedIndicators.size < DISPLAY_INDICES.length ? DISPLAY_INDICES : []
    );
    this.setState({ expandedIndicators });
  };

  rowGroupClickHandler = (id) => () => {
    const expandedIndicators = new Set(this.state.expandedIndicators);
    if (expandedIndicators.has(id)) {
      expandedIndicators.delete(id);
    } else {
      expandedIndicators.add(id);
    }
    this.setState({ expandedIndicators });
  };

  rowToggleClickHandler = (id) => () => {
    const enabledIndicators = new Set(this.state.enabledIndicators);
    if (enabledIndicators.has(id)) {
      enabledIndicators.delete(id);
    } else {
      enabledIndicators.add(id);
    }
    this.setState({
      enabledIndicators,
    });
  };

  rowInfoClickHandler = (id) => () => {
    const title = structure.find((i) => i.id === id).title;
    const rows = [];
    const findChildNodes = function(id) {
      const children = structure.filter((i) => i.parent === id);
      children.forEach((i) => {
        const source = indicatorSources.find((j) => j.id === i.id);
        if (source && source.source) rows.push([source.title, source.source]);
        findChildNodes(i.id);
      });
    };
    findChildNodes(id);
    this.setState({
      activePopoverContent: {
        title,
        columns: ['Indicators', 'Source'],
        rows,
        link: {
          title: this.props.downloadLabel,
          href: this.props.downloadUrl || '#',
        },
      },
      showPopover: true,
    });
  };

  popoverCloseClickHandler = () => {
    this.setState({
      showPopover: false,
    });
  };

  render() {
    const { className } = this.props;
    const { activePopoverContent, expandedIndicators, enabledIndicators, showPopover } = this.state;
    const { countryCount, countryData } = this.deriveStateFromProps(this.props, enabledIndicators);
    const allExpanded = expandedIndicators.size === DISPLAY_INDICES.length;

    if (!countryData) return null;

    return (
      <>
        <Table className={className || ''}>
          <Header>
            <HeaderRow>
              <HeaderCell>{`Rank/${countryCount}`}</HeaderCell>
              <HeaderCell>
                <ExpandButton clickHandler={this.headerCellClickHandler} expanded={allExpanded}>
                  {allExpanded
                    ? strings['table-head-indicator-expanded']
                    : strings['table-head-indicator']}
                </ExpandButton>
              </HeaderCell>
              <HeaderCell>{`Score/${SCALE_MAX}`}</HeaderCell>
            </HeaderRow>
            <Row {...countryData.find((d) => d.level === 0)} variant={'thick'} enabled={true} />
          </Header>
          {countryData
            .filter((d) => DISPLAY_INDICES.includes(d.id))
            .map((indexData) => (
              <RowGroup key={indexData.id}>
                <Row
                  clickHandler={this.rowGroupClickHandler(indexData.id)}
                  toggleHandler={
                    TREND_INDICATORS.includes(indexData.id) &&
                    this.rowToggleClickHandler(indexData.id)
                  }
                  expanded={expandedIndicators.has(indexData.id)}
                  expandable={true}
                  enabled={
                    !TREND_INDICATORS.includes(indexData.id) || enabledIndicators.has(indexData.id)
                  }
                  {...indexData}
                />
                {expandedIndicators.has(indexData.id) &&
                  countryData
                    .filter((d) => d.parent === indexData.id)
                    .map((indexData) => {
                      return (
                        <Row
                          key={indexData.id}
                          clickHandler={this.rowInfoClickHandler(indexData.id)}
                          toggleHandler={this.rowToggleClickHandler(indexData.id)}
                          variant={'thin'}
                          enabled={true}
                          hasInfo={true}
                          {...indexData}
                        />
                      );
                    })}
              </RowGroup>
            ))}
        </Table>
        <InfoPopover
          show={showPopover}
          data={activePopoverContent}
          onClose={this.popoverCloseClickHandler}
        />
      </>
    );
  }
}

export default ResultsTable;

function mapStateToProps({ data, activeComparisonId }) {
  return { data, activeComparisonId };
}

const ConnectedResultsTable = connect(
  mapStateToProps,
  actions
)(ResultsTable);

export { ConnectedResultsTable };
