import getNumberWithOrdinal from './getNumberWithOrdinal.js';

import { SCORE_PRECISION } from '../constants';

export default function rankScores(arr) {
  /* Iterate array and assign rank to each element
   *
   * If an element's truncated score matches the previous element's, assign the
   * same rank as the previous element and mark both with '=' if not already
   * marked. If not matching, the rank is the array index + 1.
   *
   * This allows a progression of, for example:
   *
   * > 1st, =2nd, =2nd, 4th, 5th...
   *
   */
  for (let i = 0; i < arr.length; i++) {
    let rank = getNumberWithOrdinal(i + 1);
    if (
      i > 0 &&
      arr[i].score.toFixed(SCORE_PRECISION) === arr[i - 1].score.toFixed(SCORE_PRECISION)
    ) {
      if (!arr[i - 1].rank.startsWith('=')) {
        arr[i - 1].rank = '=' + arr[i - 1].rank;
      }
      rank = arr[i - 1].rank;
    }
    arr[i] = {
      ...arr[i],
      rank,
    };
  }
}
