import React from 'react';

import { radialLine } from 'd3-shape';

import palettes from 'data/palettes.json';

import {
  findDistance,
  findBearing,
  findPointOnCircle,
  findMidpoint,
  findQuarterpoint,
  findPointOnLine,
} from 'utils/geometry.js';

const SLICE_COUNT = 16;
const SLICE = 360 / SLICE_COUNT;

export const toPath = function(points) {
  /* Convert an array of {x,y} points to path syntax
   */
  return `M ${points.map((p) => `${p.x},${p.y}`).join(' ')}`;
};

export const axisLabelGenerator = function(position, maxRadius, label) {
  /* Return an SVG text element at the end of the given axis position
   */
  const sliceOffset = 3;
  const centerSlice = (position * 2 + sliceOffset) * SLICE;
  const point = findPointOnCircle(0, 0, maxRadius + 10, centerSlice);
  return (
    <text
      key={position}
      className={'radar-chart-svg__axis-label'}
      x={point.x}
      y={point.y}
      textAnchor={position <= 4 ? 'end' : 'start'}
    >
      {label}
    </text>
  );
};

export const axisLineGenerator = function(innerRadius, outerRadius) {
  /* Return SVG path element for gridlines to connect octagons
   *
   * `a + 0.125` adds 22.5º extra rotation (360º ÷ 8 ÷ 2) so the sides of the
   * resulting shape align with 0º, 90º etc
   *
   * source: http://datawanderings.com/2019/04/14/drawing-radial-shapes-in-d3-js/
   */
  const radialLineGenerator = radialLine();
  const lines = [];
  for (let a = 0; a <= 2; a += 0.25) {
    lines.push(
      radialLineGenerator([
        [Math.PI * (a + 0.125), innerRadius],
        [Math.PI * (a + 0.125), outerRadius],
      ])
    );
  }
  return <path className={'radar-chart-svg__path'} d={lines.join(' ')} />;
};

export const maskSliceGenerator = function(innerRadius, outerRadius) {
  /* Draw a slice for use in the radar chart, starting at the origin, moving
   * to the lower-right corner then out to the lower-right corners of the
   * octagon at the outer radius.
   *
   * Return path syntax
   */
  // Expand radius slightly to ensure gridlines are covered
  outerRadius++;

  const startSlice = SLICE * 1;
  const centerSlice = SLICE * 3;
  const endSlice = SLICE * 5;

  const innerCornerPoint = findPointOnCircle(0, 0, innerRadius, centerSlice);
  const outerCornerPoints = [
    findMidpoint(
      findPointOnCircle(0, 0, outerRadius, startSlice),
      findPointOnCircle(0, 0, outerRadius, centerSlice)
    ),
    findPointOnCircle(0, 0, outerRadius, centerSlice),
    findMidpoint(
      findPointOnCircle(0, 0, outerRadius, centerSlice),
      findPointOnCircle(0, 0, outerRadius, endSlice)
    ),
  ];
  const points = [innerCornerPoint, ...outerCornerPoints, innerCornerPoint];
  return toPath(points);
};

export const octagonGenerator = function(key, radius) {
  /* Return SVG path element for an octagon that would be inscribed by a circle
   * of the given radius
   *
   * `a + 0.125` adds 22.5º extra rotation (360º ÷ 8 ÷ 2) so the sides of the
   * resulting shape align with 0º, 90º etc
   *
   * source: http://datawanderings.com/2019/04/14/drawing-radial-shapes-in-d3-js/
   */
  const radialLineGenerator = radialLine();
  const radialPoints = [];
  for (let a = 0; a <= 2; a += 0.25) {
    radialPoints.push([Math.PI * (a + 0.125), radius]);
  }
  return (
    <path key={key} className={'radar-chart-svg__path'} d={radialLineGenerator(radialPoints)} />
  );
};

export const scaleLabelGenerator = function(scalePoints, innerRadius, outerRadius) {
  /* Return an array of points where scale labels should be positioned, by
   * measuring the distance and bearing between the intended start and end
   * points of the scale and creating proportional points along that line
   */
  const startSlice = SLICE * 1;
  const centerSlice = SLICE * 3;

  const innerCornerPoint = findPointOnCircle(0, 0, innerRadius, centerSlice);
  const outerCornerPoint = findMidpoint(
    findPointOnCircle(0, 0, outerRadius, startSlice),
    findPointOnCircle(0, 0, outerRadius, centerSlice)
  );
  const distance = findDistance(innerCornerPoint, outerCornerPoint);
  const points = [];
  for (let i = 0; i < scalePoints.length; i++) {
    const radius = i * (distance / (scalePoints.length - 1));
    points.push(findPointOnLine(innerCornerPoint, outerCornerPoint, radius));
  }

  return points.map((point, i) => {
    if (i === 0) {
      return null;
    }
    const { x, y } = point;
    const text = scalePoints[i];
    return (
      <text
        key={i}
        className={'radar-chart-svg__scale-label'}
        x={x}
        y={y}
        dx="-0.2em"
        dy="1em"
        textAnchor={'end'}
      >
        {text}
      </text>
    );
  });
};

export const seriesPolygonGenerator = function(
  pos,
  first,
  last,
  id,
  score,
  highScore,
  seriesData,
  comparator,
  activeSeriesId,
  minRadius,
  maxRadius,
  onMouseOver,
  onMouseOut,
  url
) {
  /* Return path elements for highScore and score values in the given series
   */
  // Bump scores slightly to ensure overlap with gridlines
  score++;
  highScore++;

  // Determine central slice position from `pos` and offset (to start bottom-right)
  const sliceOffset = 3;
  let centerSlice = pos * 2 + sliceOffset;
  let startSlice = centerSlice - 2;
  let endSlice = centerSlice + 2;

  startSlice *= SLICE;
  centerSlice *= SLICE;
  endSlice *= SLICE;

  const innerStartPoint = findPointOnCircle(0, 0, minRadius, startSlice);
  const innerCenterPoint = findPointOnCircle(0, 0, minRadius, centerSlice);
  const innerEndPoint = findPointOnCircle(0, 0, minRadius, endSlice);

  const outerStartPoint = findPointOnCircle(0, 0, maxRadius, startSlice);
  const outerCenterPoint = findPointOnCircle(0, 0, maxRadius, centerSlice);
  const outerEndPoint = findPointOnCircle(0, 0, maxRadius, endSlice);

  const highScoreStartPoint = findPointOnCircle(0, 0, highScore, startSlice);
  const highScoreCenterPoint = findPointOnCircle(0, 0, highScore, centerSlice);
  const highScoreEndPoint = findPointOnCircle(0, 0, highScore, endSlice);

  const scoreStartPoint = findPointOnCircle(0, 0, score, startSlice);
  const scoreCenterPoint = findPointOnCircle(0, 0, score, centerSlice);
  const scoreEndPoint = findPointOnCircle(0, 0, score, endSlice);

  let hitboxInnerStartPoint = findMidpoint(innerStartPoint, innerCenterPoint);
  let hitboxInnerEndPoint = findMidpoint(innerCenterPoint, innerEndPoint);
  if (first) hitboxInnerEndPoint = innerEndPoint;
  else if (last) hitboxInnerStartPoint = innerStartPoint;
  const hitboxOuterStartPoint = findMidpoint(outerStartPoint, outerCenterPoint);
  const hitboxOuterEndPoint = findMidpoint(outerCenterPoint, outerEndPoint);

  const sliceBearing = findBearing(innerCenterPoint, outerCenterPoint);
  const color = palettes[id][0];

  const distributionMaskInnerStartPoint = findQuarterpoint(innerCenterPoint, innerStartPoint);
  const distributionMaskInnerEndPoint = findQuarterpoint(innerCenterPoint, innerEndPoint);
  const distributionMaskOuterStartPoint = findPointOnCircle(
    distributionMaskInnerStartPoint.x,
    distributionMaskInnerStartPoint.y,
    maxRadius,
    sliceBearing
  );
  const distributionMaskOuterEndPoint = findPointOnCircle(
    distributionMaskInnerEndPoint.x,
    distributionMaskInnerEndPoint.y,
    maxRadius,
    sliceBearing
  );

  const maskShapePoints = [
    innerStartPoint,
    findMidpoint(outerStartPoint, outerCenterPoint),
    outerCenterPoint,
    findMidpoint(outerCenterPoint, outerEndPoint),
    innerEndPoint,
    innerCenterPoint,
  ];

  const highScoreLinePoints = [highScoreStartPoint, highScoreCenterPoint, highScoreEndPoint];

  const highScoreShapePoints = [
    innerStartPoint,
    ...highScoreLinePoints,
    innerEndPoint,
    innerCenterPoint,
  ];

  const scoreShapePoints = [
    innerStartPoint,
    scoreStartPoint,
    scoreCenterPoint,
    scoreEndPoint,
    innerEndPoint,
    innerCenterPoint,
  ];

  const distributionMaskPoints = [
    innerCenterPoint,
    distributionMaskInnerStartPoint,
    distributionMaskOuterStartPoint,
    outerCenterPoint,
    distributionMaskOuterEndPoint,
    distributionMaskInnerEndPoint,
  ];

  const hitboxPoints = [
    hitboxInnerStartPoint,
    hitboxOuterStartPoint,
    outerCenterPoint,
    hitboxOuterEndPoint,
    hitboxInnerEndPoint,
    innerCenterPoint,
  ];

  let comparatorPoint;
  if (comparator !== false) {
    comparatorPoint = findPointOnCircle(0, 0, comparator, centerSlice);
  }

  const hitboxPath = (
    <path
      // XXX: Need to use `mouseOver/mouseOut` instead of `mouseEnter/mouseLeave` in IE11
      // FIXME: This can be avoided by pathing the SVGElement object
      onMouseOver={onMouseOver(id)}
      onMouseOut={onMouseOut}
      onTouchStart={onMouseOver(id)}
      onTouchEnd={onMouseOut}
      onTouchCancel={onMouseOut}
      d={toPath(hitboxPoints)}
      fill={'transparent'}
    />
  );

  return (
    <g key={id}>
      <g
        style={{
          pointerEvents: 'none',
          opacity: activeSeriesId && activeSeriesId !== id ? 0.2 : 1,
        }}
      >
        <mask id={`series-${id}`}>
          <rect x={0} y={0} width={'100%'} height={'100%'} fill={'black'} />
          <path d={toPath(maskShapePoints)} fill={'white'} />
        </mask>
        <g mask={`url(#series-${id})`}>
          <path d={toPath(highScoreShapePoints)} fill={color} fillOpacity={0.1} />
          <path d={toPath(highScoreLinePoints)} fill={'transparent'} stroke={color} />
          <path d={toPath(scoreShapePoints)} fill={color} fillOpacity={0.8} />
        </g>
        {activeSeriesId && activeSeriesId === id && seriesData !== false && (
          <g>
            <mask id={`series-${id}-distribution`}>
              <rect x={0} y={0} width={'100%'} height={'100%'} fill={'black'} />
              <path d={toPath(distributionMaskPoints)} fill={'white'} />
            </mask>
            <g mask={`url(#series-${id}-distribution)`}>
              {seriesData.map((d, i) => {
                const points = [
                  findPointOnCircle(0, 0, d.score, startSlice),
                  findPointOnCircle(0, 0, d.score, centerSlice),
                  findPointOnCircle(0, 0, d.score, endSlice),
                ];
                return (
                  <path
                    key={i}
                    d={toPath(points)}
                    fill={'transparent'}
                    stroke={'#fff'}
                    strokeOpacity={0.4}
                  />
                );
              })}
            </g>
          </g>
        )}
        {comparator !== false && (
          <circle cx={comparatorPoint.x} cy={comparatorPoint.y} r={2.5} fill={'white'} />
        )}
      </g>
      {url ? (
        // XXX: React style tabIndex prop does not work on SVG elements in Preact
        <a href={url} xlinkHref={url} tabindex="-1">
          {hitboxPath}
        </a>
      ) : (
        hitboxPath
      )}
    </g>
  );
};
