export default function dataSorter(precision) {
  return function dataSorterFn(a, b) {
    /* If a and b's truncated scores are the same, subsort on names, otherwise
     * sort based on the full score values
     */
    const aToFixed = a.score.toFixed(precision);
    const bToFixed = b.score.toFixed(precision);
    if (aToFixed === bToFixed) {
      if (a.name > b.name) return 1;
      else if (a.name < b.name) return -1;
      else return 0;
    } else {
      if (a.score > b.score) return 1;
      else if (a.score < b.score) return -1;
      else return 0;
    }
  };
}
