import React from 'react';
import { SizeMe } from 'react-sizeme';
import { connect } from 'redux-zero/react';

import Icon from 'components/react/Icon';
import RadarChartSvg from 'components/react/RadarChartSvg';

import { actions } from '../../store.js';

import strings from 'data/strings.json';

import './styles.scss';

class HomepageRadarChart extends React.PureComponent {
  constructor(props) {
    super(props);
    const defaultCaption = {
      title: strings['indicator-description-default-title'],
      description: strings['indicator-description-default'],
      showLink: true,
    };
    this.state = {
      activeSeriesId: null,
      defaultCaption,
      seriesCaption: defaultCaption,
    };
  }

  componentDidMount() {
    if (!this.props.data && this.props.fetchData) {
      this.props.fetchData();
    }
  }

  onMouseOver = (seriesId) => (event) => {
    if (seriesId === 'P') {
      return;
    }

    this.setState({
      activeSeriesId: seriesId,
      seriesCaption: {
        title: strings[`indicator-title-${seriesId}`],
        description: strings[`indicator-description-${seriesId}`],
        showLink: false,
      },
    });
  };

  onMouseOut = (event) => {
    this.setState({
      activeSeriesId: null,
      seriesCaption: this.state.defaultCaption,
    });
  };

  render() {
    const {
      data,
      className,
      activeCountryId,
      seriesCaptionLinkText,
      seriesCaptionLinkTarget,
      indicatorRoutes,
    } = this.props;
    const { activeSeriesId, seriesCaption } = this.state;

    if (!data) {
      return null;
    }

    return (
      <div className={`homepage-radar-chart ${className || ''}`}>
        <div className={'homepage-radar-chart__grid'}>
          <div className={'homepage-radar-chart__inner'}>
            <SizeMe monitorHeight>
              {({ size }) => (
                <div>
                  <RadarChartSvg
                    className={'homepage-radar-chart__svg'}
                    size={size}
                    data={data}
                    urls={indicatorRoutes}
                    activeCountryId={activeCountryId}
                    activeSeriesId={activeSeriesId}
                    onMouseOver={this.onMouseOver}
                    onMouseOut={this.onMouseOut}
                  />
                </div>
              )}
            </SizeMe>
          </div>
          <div className={'homepage-radar-chart__caption-container'}>
            <div className={'homepage-radar-chart__caption-container__inner'}>
              {seriesCaption && (
                <>
                  <h2 className={'homepage-radar-chart__caption-container__title'}>
                    {seriesCaption.title}
                  </h2>
                  <p className={'homepage-radar-chart__caption-container__copy'}>
                    {seriesCaption.description}
                  </p>
                  {seriesCaption.showLink && (
                    <a
                      className={'homepage-radar-chart__caption-container__link'}
                      href={seriesCaptionLinkTarget}
                    >
                      <Icon id={'arrow'} />
                      {seriesCaptionLinkText}
                    </a>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomepageRadarChart;

function mapStateToProps({ data }) {
  return { data };
}

const ConnectedHomepageRadarChart = connect(
  mapStateToProps,
  actions
)(HomepageRadarChart);

export { ConnectedHomepageRadarChart };
