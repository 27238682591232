import rankScores from './rankScores.js';
import dataSorter from './dataSorter.js';

import { SCORE_PRECISION, TREND_INDICATORS } from '../constants.js';

const TRENDS_WEIGHT = 2 / 3;
const PRECONDITIONS_WEIGHT = 1 / 3;

export function calculateTotalScores(data, enabledIndicators) {
  return data.map((datum) => {
    if (datum.id === 'FPI') {
      const trendsData = data.filter((d) => {
        return enabledIndicators.has(d.id) && d.country === datum.country;
      });
      const preconditionsData = data.find((d) => {
        return d.id === 'P' && d.country === datum.country;
      });
      const score =
        trendsData.reduce((total, d) => {
          // Overall score must be calculated using data without predcondition added
          return total + d.originalScore * (TRENDS_WEIGHT / trendsData.length);
        }, 0) +
        preconditionsData.score * PRECONDITIONS_WEIGHT;
      return { ...datum, score: score };
    } else {
      return datum;
    }
  });
}

export function addPreconditionsToTrends(data) {
  return data.map((datum) => {
    if (TREND_INDICATORS.includes(datum.id)) {
      const preconditionsData = data.find((d) => {
        return d.id === 'P' && d.country === datum.country;
      });
      const score = datum.score * TRENDS_WEIGHT + preconditionsData.score * PRECONDITIONS_WEIGHT;
      return { ...datum, score: score, originalScore: datum.score };
    } else {
      return datum;
    }
  });
}

export function addRanks(data, indicators) {
  const sorter = dataSorter(SCORE_PRECISION);
  let buffer = [];

  indicators.forEach((indicator) => {
    const datum = data
      .filter((d) => !d.aggregate)
      .filter((d) => d.id === indicator)
      .sort(sorter)
      .reverse();

    rankScores(datum);

    buffer = buffer.concat(datum);
  });

  return buffer;
}
