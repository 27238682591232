let supportsPassiveEvents = false;

try {
  const options = Object.defineProperty({}, 'passive', {
    get: function() {
      supportsPassiveEvents = true;
    },
  });
  const noop = function() {};
  window.addEventListener('test', noop, options);
  window.removeEventListener('test', noop, options);
} catch (err) {}

export { supportsPassiveEvents };
