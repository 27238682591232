import 'element-closest/browser.js';

export default class ShareButtonsBehaviour {
  static id = 'share-buttons';

  constructor(node) {
    this.node = node;

    this.url = window.location.href.replace(/#.+$/, '');

    if (window.__ENV__) {
      this.twitterMessage = window.__ENV__.twitterMessage || document.title;
      this.twitterHandle = window.__ENV__.twitterHandle;
      this.emailSubject = window.__ENV__.emailSubject || document.title;
      this.emailBody = window.__ENV__.emailBody;

      this.updateURLs();
    }

    this.node.addEventListener('click', this.handleClick);
  }

  updateURLs() {
    const url = encodeURIComponent(this.url);
    const emailSubject = encodeURIComponent(this.emailSubject);
    const emailBody = this.emailBody ? `${encodeURIComponent(this.emailBody)}%0A%0A` : '';
    const twitterMessage = encodeURIComponent(this.twitterMessage);
    const twitterHandle =
      this.twitterHandle && encodeURIComponent(this.twitterHandle.replace('@', ''));
    const nodes = this.node.querySelectorAll('[data-ref]');

    for (let node of nodes) {
      let href = '';
      switch (node.dataset.ref) {
        case 'email':
          href = `mailto:?subject=${emailSubject}&body=${emailBody}${url}`;
          break;
        case 'facebook':
          href = `https://www.facebook.com/sharer.php?u=${url}`;
          break;
        case 'linkedin':
          href = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;
          break;
        case 'twitter':
          href = `https://twitter.com/intent/tweet?text=${twitterMessage}&url=${url}&via=${twitterHandle}`;
          break;
        case 'whatsapp':
          href = `https://wa.me/?text=${url}`;
          break;
      }
      node.setAttribute('href', href);
    }
  }

  handleClick = (event) => {
    const anchor = event.target.closest('a');

    if (!anchor || anchor.protocol === 'mailto:') {
      return;
    }

    event.preventDefault();
    this.openWindow(anchor.href);
  };

  openWindow = (url, w, h) => {
    w = w || 550;
    h = h || 350;
    const sw = window.screen.width * (window.devicePixelRatio || 1);
    const sh = window.screen.height * (window.devicePixelRatio || 1);
    const x = sw / 2 - w / 2;
    const y = sh / 4 - h / 2;

    window.open(
      url,
      'share',
      `height=${h},width=${w},top=${y},left=${x},toolbar=0,location=0,menubar=0,directories=0,scrollbars=0`
    );
  };
}
