export default function normalisePointerEvent(event) {
  /* Return {x,y} coordinates of touch or mouse event
   */
  let pointer = { x: 0, y: 0 };
  if (['touchstart', 'touchmove'].includes(event.type)) {
    const touch = event.touches[0] || event.changedTouches[0];
    pointer.x = touch.clientX;
    pointer.y = touch.clientY;
  } else if (['mouseenter', 'mousemove'].includes(event.type)) {
    pointer.x = event.clientX;
    pointer.y = event.clientY;
  }
  return pointer;
}
