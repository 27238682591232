export default class DownloadMenuBehaviour {
  static id = 'download-menu';

  constructor(node) {
    const triggerNode = node.querySelector('[aria-expanded]');
    const targetNode = triggerNode.nextElementSibling;

    triggerNode.addEventListener('click', () => {
      const hidden = targetNode.hasAttribute('hidden');
      if (hidden) {
        triggerNode.setAttribute('aria-expanded', true);
        targetNode.removeAttribute('hidden');
      } else {
        triggerNode.setAttribute('aria-expanded', false);
        targetNode.setAttribute('hidden', true);
      }
    });
  }
}
