import React from 'react';
import { connect } from 'redux-zero/react';

import Select from 'components/react/Select';

import { actions } from '../../store.js';

import countries from 'data/countries.json';
import groups from 'data/groups.json';
import strings from 'data/strings.json';

import './styles.scss';

const GROUP_OPTIONS = [['regions', 'Regions'], ['incomeLevels', 'Income Levels']].map((g) => {
  const [key, label] = g;
  return {
    label,
    options: Object.keys(groups[key]).map((value) => ({
      value,
      label: groups[key][value].name,
    })),
  };
});
const COMPARISON_OPTIONS = [
  ...GROUP_OPTIONS,
  {
    label: 'Countries',
    options: Object.keys(countries).map((iso) => ({
      value: iso,
      label: countries[iso].name,
    })),
  },
];

class ComparisonSelect extends React.PureComponent {
  handleSelectChange = (event) => {
    const id = event.target.value;
    if (this.props.setActiveComparison) {
      this.props.setActiveComparison(id);
    }
  };

  render() {
    const { activeCountryId, activeComparisonId } = this.props;

    return (
      <div className="comparison-select">
        <label htmlFor="comparison">{strings['table-label-comparison']}</label>
        <Select
          id="comparison"
          optGroups={COMPARISON_OPTIONS}
          selectedOption={activeComparisonId}
          defaultOption="Select comparison…"
          disabledOption={activeCountryId}
          changeHandler={this.handleSelectChange}
        />
      </div>
    );
  }
}

export default ComparisonSelect;

function mapStateToProps({ data, activeComparisonId }) {
  return { data, activeComparisonId };
}

const ConnectedComparisonSelect = connect(
  mapStateToProps,
  actions
)(ComparisonSelect);

export { ConnectedComparisonSelect };
