import React from 'react';

import Icon from 'components/react/Icon';
import {
  Footer,
  Header,
  HeaderCell,
  HeaderRow,
  Row,
  RowGroup,
  Table,
  SortButton,
} from 'components/react/Table';

import dataSorter from 'utils/dataSorter';
import rankScores from 'utils/rankScores';

import countries from 'data/countries.json';
// import data from 'data/data.json';
import palettes from 'data/palettes.json';

import { SCORE_PRECISION, SCALE_MAX } from '../../constants';

const sorter = dataSorter(SCORE_PRECISION);

class CountriesTable extends React.PureComponent {
  constructor(props) {
    super(props);

    let countryCount;
    const { data, activeIndex } = this.props;
    const indexData = data
      .filter((d) => !d.aggregate)
      .sort(sorter)
      .map((d) => ({
        ...d,
        name: countries[d.country].name,
        color: palettes[activeIndex][0],
        url: this.props.countryRoutes[d.country],
      }))
      .reverse();
    rankScores(indexData);

    this.state = {
      indexData,
      truncated: true,
      sortDirection: 'asc',
    };
  }

  showAllClickHandler = () => {
    this.setState({
      truncated: !this.state.truncated,
    });
  };

  toggleSortDirection = () => {
    this.setState({
      sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc',
      indexData: this.state.indexData.reverse(),
    });
  };

  render() {
    const { className, show } = this.props;
    const { allIndexData, indexData, truncated, sortDirection } = this.state;
    return (
      <Table className={className || ''} compact>
        <Header>
          <HeaderRow>
            <HeaderCell>
              <SortButton direction={sortDirection} clickHandler={this.toggleSortDirection}>
                {`Rank/${indexData.length}`}
              </SortButton>
            </HeaderCell>
            <HeaderCell>Country</HeaderCell>
            <HeaderCell>{`Score/${SCALE_MAX}`}</HeaderCell>
          </HeaderRow>
        </Header>
        {indexData.slice(0, truncated ? show : indexData.length).map((countryData, i) => (
          <RowGroup key={countryData.country}>
            <Row enabled={true} {...countryData} />
          </RowGroup>
        ))}
        {show && show < indexData.length && (
          <Footer>
            <button onClick={this.showAllClickHandler}>
              {`${truncated ? 'Show all' : `Show top ${show}`}`}
            </button>
          </Footer>
        )}
      </Table>
    );
  }
}

CountriesTable.defaultProps = {
  countryRoutes: {},
};

export default CountriesTable;
