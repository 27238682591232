import React from 'react';

import Icon from '../Icon';

import strings from 'data/strings.json';

import { SCORE_PRECISION } from '../../../constants';

import './styles.scss';

export const ExpandButton = React.memo(function({ className, expanded, clickHandler, children }) {
  return (
    <button
      className={`${className || ''} results-table__expand-button ${
        expanded ? 'results-table__expand-button--expanded' : ''
      }`}
      onClick={clickHandler}
    >
      <Icon id={'arrow'} />
      {children}
    </button>
  );
});
ExpandButton.displayName = 'ExpandButton';

export const Footer = React.memo(function({ children }) {
  return (
    <tfoot className={'results-table-footer'}>
      <tr>
        <td></td>
        <td>{children}</td>
        <td></td>
      </tr>
    </tfoot>
  );
});
Footer.displayName = 'Footer';

export const Header = React.memo(function({ children }) {
  return <thead className={'results-table-header'}>{children}</thead>;
});
Header.displayName = 'Header';

export const HeaderCell = React.memo(function({ children }) {
  return <th className={'results-table-header-cell'}>{children}</th>;
});
HeaderCell.displayName = 'HeaderCell';

export const HeaderRow = React.memo(function({ children }) {
  return <tr className={'results-table-header-row'}>{children}</tr>;
});
HeaderRow.displayName = 'HeaderRow';

export const InfoButton = React.memo(function({ className, clickHandler, children }) {
  return (
    <button className={`${className || ''} results-table__info-button`} onClick={clickHandler}>
      <Icon id={'information'} />
      {children}
    </button>
  );
});
InfoButton.displayName = 'InfoButton';

export const Row = React.memo(function({
  id,
  level,
  parent,
  rank,
  name,
  score,
  comparator,
  highScore,
  color,
  enabled,
  expandable,
  expanded,
  hasInfo,
  clickHandler,
  toggleHandler,
  variant,
  url,
}) {
  return (
    <>
      <tr
        className={`results-table-row results-table-row--indicators ${
          variant ? `results-table-row--${variant}` : ''
        } ${enabled ? '' : 'is-disabled'}`}
      >
        <td></td>
        <td colSpan={2} className={'results-table-row__indicators'}>
          <div
            className={'results-table-row__score-bar'}
            style={{ width: `${score}%`, backgroundColor: color }}
          />
          {comparator && (
            <div
              className={'results-table-row__comparator'}
              style={{ left: `${comparator}%` }}
              data-label={strings['table-label-comparison']}
            />
          )}
          {highScore && (
            <div
              className={'results-table-row__high-score'}
              style={{ left: `${highScore}%` }}
              data-label={strings['table-label-best']}
            />
          )}
        </td>
      </tr>
      <tr
        className={`results-table-row ${variant ? `results-table-row--${variant}` : ''} ${
          enabled ? '' : 'is-disabled'
        }`}
      >
        <td className={'results-table-row__rank'}>
          <div className={'results-table-row__rank__label'}>{rank}</div>
          <div className={'results-table-row__rank__toggle'}>
            {expandable && toggleHandler && (
              <ToggleButton type={'checkbox'} enabled={enabled} clickHandler={toggleHandler} />
            )}
          </div>
        </td>
        <td className={'results-table-row__details'}>
          {expandable && clickHandler ? (
            <ExpandButton
              className={'results-table-row__name'}
              clickHandler={clickHandler}
              expanded={expanded}
            >
              {name}
            </ExpandButton>
          ) : hasInfo && clickHandler ? (
            <InfoButton className={'results-table-row__name'} clickHandler={clickHandler}>
              {name}
            </InfoButton>
          ) : url ? (
            <div className={'results-table-row__name'}>
              <a href={url}>{name}</a>
            </div>
          ) : (
            <div className={'results-table-row__name'}>{name}</div>
          )}
        </td>
        <td className={'results-table-row__score'}>{score.toFixed(SCORE_PRECISION)}</td>
      </tr>
    </>
  );
});
Row.displayName = 'Row';

export const RowGroup = React.memo(function({ children, expanded }) {
  return (
    <tbody
      className={`results-table-row-group ${expanded ? 'results-table-row-group--expanded' : ''}`}
    >
      {children}
    </tbody>
  );
});
RowGroup.displayName = 'RowGroup';

export const Table = React.memo(function({ className, compact, children }) {
  return (
    <table
      className={`results-table ${compact ? 'results-table--compact' : ''} ${className || ''}`}
    >
      {children}
    </table>
  );
});
Table.displayName = 'Table';

export const SortButton = React.memo(function({ className, direction, clickHandler, children }) {
  return (
    <button
      className={`${className ||
        ''} results-table__sort-button results-table__sort-button--${direction}`}
      onClick={clickHandler}
    >
      <Icon id={'sorting-arrow'} />
      {children}
    </button>
  );
});
SortButton.displayName = 'SortButton';

export const ToggleButton = React.memo(function({ className, enabled, clickHandler }) {
  return (
    <button
      className={`${className || ''} results-table__toggle-button ${enabled ? 'is-enabled' : ''}`}
      onClick={clickHandler}
      aria-label={'Toggle'}
    >
      <Icon id={'tick'} />
    </button>
  );
});
ToggleButton.displayName = 'ToggleButton';
