import React from 'react';

import './styles.scss';

const Icon = function({ id, className }) {
  return (
    <svg className={`icon icon--${id} ${className || ''}`}>
      <use xlinkHref={`#icon-${id}`} />
    </svg>
  );
};

export default React.memo(Icon);
