import createStore from 'redux-zero';

import { addPreconditionsToTrends } from './utils/data.js';

const INITIAL_STATE = {
  data: null,
  loading: false,
  activeComparisonId: null,
  ...(window.initialState || {}),
};

export const actions = (store) => ({
  fetchData: async (state) => {
    if (state.loading) {
      return;
    }

    store.setState({ loading: true });

    // Abuse Webpack dynamic import to load JSON data
    return import('./data/data.json').then((data) => {
      const processedData = addPreconditionsToTrends(data.default);
      return { data: processedData, loading: false };
    });
  },
  setActiveComparison: (state, value) => {
    return { activeComparisonId: value };
  },
});

export const store = createStore(INITIAL_STATE);
