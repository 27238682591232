import React, { lazy, Suspense } from 'react';
import { extent } from 'd3-array';
import { scaleQuantize } from 'd3-scale';
import { SizeMe } from 'react-sizeme';

import ChartTooltip from 'components/react/ChartTooltip';
import WorldMapScale from 'components/react/WorldMapScale';
// import WorldMapSvg from 'components/react/WorldMapSvg';
const WorldMapSvg = lazy(() => import('components/react/WorldMapSvg'));

import getNumberWithOrdinal from 'utils/getNumberWithOrdinal';

import countries from 'data/countries.json';
import palettes from 'data/palettes.json';

import { SCORE_PRECISION } from '../../constants';

import './styles.scss';

class WorldMap extends React.PureComponent {
  constructor(props) {
    super(props);

    const { data, activeIndex } = this.props;
    const indexData = data.sort((a, b) => a.score - b.score).reverse();
    const scaleData = indexData.map((d) => d.score);
    const scaleColors = [...(palettes[activeIndex] || palettes['FPI'])].reverse();
    const scale = scaleQuantize()
      .domain(extent(scaleData))
      .range(scaleColors);

    this.state = {
      indexData,
      scale,
      tooltip: null,
      activeCountryId: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.activeCountryId && prevState.activeCountryId !== this.state.activeCountryId) {
      const countryId = this.state.activeCountryId;
      const countryScore = this.state.indexData
        .find((d) => d.country === countryId)
        .score.toFixed(SCORE_PRECISION);
      const rank = this.state.indexData.findIndex((d) => d.country === countryId) + 1;

      this.setState({
        tooltip: {
          title: countries[countryId].name,
          primaryText: `Score ${countryScore}`,
          secondaryText: `Rank ${getNumberWithOrdinal(rank)}`,
        },
      });
    }
  }

  onMouseOver = (countryId) => (event) => {
    this.setState({
      activeCountryId: countryId,
    });
  };

  onMouseOut = (event) => {
    this.setState({
      activeCountryId: null,
      tooltip: null,
    });
  };

  render() {
    const { className, countryRoutes } = this.props;
    const { indexData, scale, tooltip, activeCountryId } = this.state;
    return (
      <div className={`world-map ${className || ''}`}>
        <div className={'world-map__grid'}>
          <div className={'world-map__inner'}>
            <SizeMe monitorHeight>
              {({ size }) => (
                <>
                  <div className="world-map__svg">
                    <Suspense fallback={null}>
                      <WorldMapSvg
                        size={size}
                        data={indexData}
                        countryRoutes={countryRoutes}
                        scale={scale}
                        onMouseOver={this.onMouseOver}
                        onMouseOut={this.onMouseOut}
                      />
                    </Suspense>
                  </div>
                  {tooltip && activeCountryId && (
                    <ChartTooltip
                      className={'world-map__tooltip'}
                      title={tooltip.title}
                      primaryText={tooltip.primaryText}
                      secondaryText={tooltip.secondaryText}
                    />
                  )}
                </>
              )}
            </SizeMe>
          </div>
        </div>
        <WorldMapScale className={'world-map__scale'} scale={scale} />
      </div>
    );
  }
}

export default WorldMap;
