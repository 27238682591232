export default function getNumberWithOrdinal(n) {
  /* Return supplied number `n` concatenated with n's ordinal suffix, e.g.
   * 1 -> '1st'
   *
   * source: https://stackoverflow.com/a/31615643/258794
   */
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return `${n}${s[(v - 20) % 10] || s[v] || s[0]}`;
}
