export const findDistance = function(point1, point2) {
  /* Find distance between two points on cartesian plane
   *
   * source: https://stackoverflow.com/a/33743107/258794
   */
  const { x: x1, y: y1 } = point1;
  const { x: x2, y: y2 } = point2;
  return Math.hypot(x2 - x1, y2 - y1);
};

export const findBearing = function(point1, point2) {
  /* Find bearing from point1 to point2 on cartesian plane
   *
   * source: https://gist.github.com/conorbuck/2606166
   */
  const { x: x1, y: y1 } = point1;
  const { x: x2, y: y2 } = point2;
  return (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI;
};

export const findPointOnCircle = function(x, y, r, deg) {
  /* Find a point on a circle of radius `r` with origin x, y at a rotation of
   * `deg` along the circle
   *
   * source: https://stackoverflow.com/a/32924508/258794
   */
  var rad = (deg * Math.PI) / 180;
  var x2 = Math.cos(rad) * r + x;
  var y2 = Math.sin(rad) * r + y;
  return { x: x2, y: y2 };
};

export const findMidpoint = function(point1, point2) {
  /* Find the midpoint between two coordinates
   */
  const { x: x1, y: y1 } = point1;
  const { x: x2, y: y2 } = point2;
  return { x: (x1 + x2) / 2, y: (y1 + y2) / 2 };
};

export const findQuarterpoint = function(point1, point2) {
  /* Find the point 1/4 of the way between point1 and point2
   */
  return findMidpoint(point1, findMidpoint(point1, point2));
};

export const findPointOnLine = function(p1, p2, l) {
  const angle = Math.atan2(p2.y - p1.y, p2.x - p1.x);
  const x = p1.x + Math.cos(angle) * l;
  const y = p1.y + Math.sin(angle) * l;
  return { x: x, y: y };
};
