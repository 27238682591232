import React from 'react';

import Icon from '../Icon';

import './styles.scss';

const Select = function({
  className,
  id,
  optGroups,
  selectedOption,
  defaultOption,
  changeHandler,
  ariaLabel,
  disabledOption,
}) {
  return (
    <div className={`${className || ''} select`}>
      <form className={'select__form'}>
        <select
          id={id}
          className={'select__select'}
          value={selectedOption || null}
          onChange={changeHandler}
          aria-label={ariaLabel || ''}
        >
          {defaultOption && <option value={null}>{defaultOption}</option>}
          {optGroups.map((g) => (
            <optgroup key={g.label} label={g.label}>
              {g.options.map((o) => (
                <option
                  key={o.value}
                  value={o.value}
                  disabled={disabledOption && disabledOption === o.value}
                >
                  {o.label}
                </option>
              ))}
            </optgroup>
          ))}
        </select>
        <div className={'select__decoration'} />
      </form>
      <Icon id={'arrow'} />
    </div>
  );
};

export default React.memo(Select);
