import React from 'react';
import { createPortal } from 'react-dom';
import { Provider } from 'redux-zero/react';

import CountriesTable from 'containers/CountriesTable';
import { ConnectedHomepageRadarChart } from 'containers/HomepageRadarChart';
import { ConnectedRadarChart } from 'containers/RadarChart';
import { ConnectedResultsTable } from 'containers/ResultsTable';
import WorldMap from 'containers/WorldMap';
import { ConnectedComparisonSelect } from 'containers/ComparisonSelect';

import { store } from './store.js';

import { parseComponents } from './utils/components.js';

const DEV_INIT_DELAY = 1000;
const COMPONENTS = {
  CountriesTable: CountriesTable,
  HomepageRadarChart: ConnectedHomepageRadarChart,
  RadarChart: ConnectedRadarChart,
  ResultsTable: ConnectedResultsTable,
  WorldMap: WorldMap,
  ComparisonSelect: ConnectedComparisonSelect,
};

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      components: [],
    };
  }

  componentDidMount() {
    if (process.env.NODE_ENV === 'development') {
      window.setTimeout(() => {
        const components = parseComponents();
        this.setState({ components });
      }, DEV_INIT_DELAY);
    } else {
      const components = parseComponents();
      this.setState({ components });
    }
  }

  render() {
    return (
      <>
        <Provider store={store}>
          {this.state.components.map((component) => {
            const Constructor = COMPONENTS[component.id];
            if (Constructor) {
              return createPortal(<Constructor {...component.props} />, component.node);
            }
          })}
        </Provider>
      </>
    );
  }
}
