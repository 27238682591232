__webpack_public_path__ = window.__ENV__.baseUrl;

import React from 'react';
import { render } from 'react-dom';

import './styles.js';

import App from './app.js';
import { initBehaviours } from './utils/components.js';
import behaviours from './components/twig/behaviours.js';

initBehaviours(behaviours);

const node = document.createElement('div');
document.body.appendChild(node);

render(<App />, node);
