import 'element-closest';

export default class TopButtonBehaviour {
  static id = 'top-button';

  constructor(node) {
    this.node = node;

    this.node.addEventListener('click', (event) => {
      event.preventDefault();
      window.scroll(0, 0);
    });
  }
}
